<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <div class="search_center">
            修改内容：
            <el-input
              class="ipt_width"
              v-model="parameter.content"
              placeholder="请输入修改内容"
            ></el-input>
          </div>
          <div class="search_center">
            姓名：
            <el-select
              v-model="parameter.createBy"
              placeholder="请选择修改人姓名"
              clearable
              filterable
              class="ipt_width"
            >
              <el-option
                v-for="item in userData"
                :label="item.userName"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="search_center">
            模块类型：
            <Dictionary
              v-model="parameter.logModuleType"
              code="LOG_MODULE_TYPE"
              placeholder="请选择模块类型"
              :filterable="true"
            />
          </div>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            row-key="id"
            v-loading="loading"
          >
            <!-- @sort-change="sortChange" -->
            <el-table-column
              align="center"
              prop="createName"
              label="修改人"
              sortable
              :show-overflow-tooltip="false"
              min-width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createdDate"
              min-width="130"
              sortable
              label="修改时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | timeFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              min-width="120"
              sortable
              prop="logModuleType"
              label="操作模块"
            >
              <template slot-scope="scope">
                {{ scope.row.logModuleType | dict(dictData.logModuleType) }}
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click.stop="viewDetail(scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 商务操作记录 -->
    <BusinessDetails :isShow.sync="businessIsShow" :options="options" />
    <!-- 项目操作记录 -->
    <ProjectDetails :isShow.sync="projectIsShow" :options="options" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    BusinessDetails: () => import('@/components/business/business-details.vue'),
    ProjectDetails: () => import('@/components/project/project-details.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        logModuleType: null,
        createBy: null,
        content: null,
      },
      pageSize: 0,
      dictData: {
        logModuleType: [],
      },
      tableData: [],
      userData: [],
      loading: false,
      options: {},
      businessIsShow: false, //商务操作记录
      projectIsShow: false, //项目操作记录
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('LOG_MODULE_TYPE', true)
      .then(res => {
        this.dictData.logModuleType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.user
      .listStaff({
        pageSize: 999999,
        pageNow: 1,
      })
      .then(res => {
        this.userData = res.data?.records
      })
      .catch(err => {
        console.log(err)
      })
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    handleReset() {
      this.parameter.logModuleType = null
      this.parameter.createBy = null
      this.parameter.content = null
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    sortChange(column) {
      this.tableData = []
      if (column) {
        const orderMapping = {
          createdDate: 'created_Date',
          createName: 'created_Name',
          logModuleType: 'log_Module_Type',
        }

        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }

        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.getData()
      }
    },
    viewDetail(row) {
      this.options = row.deepClone()
      if (row.logModuleType == 'BUSINESS') {
        this.businessIsShow = true
      } else if (row.logModuleType == 'PROJECT') {
        this.projectIsShow = true
      }
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true

      this.$api.log
        .allList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data?.records ? res.data?.records : []
          this.parameter.total = res.data.total ? res.data.total : 0
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
